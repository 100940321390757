@charset "utf-8";

@import "../node_modules/bulma/bulma";
@import "collapsible";

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.nojs .navbar-menu {
  display: flex;
}

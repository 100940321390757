$collapse-transition-speed: 0.2s;
$collapse-transition-style: ease;

.is-collapsible {
  height: 0px;
  overflow-y: hidden;
  transition: height $collapse-transition-speed $collapse-transition-style;
}

.nojs .is-collapsible {
  height: auto;
}

.nojs .is-collapsible-toggle {
  display: none;
}
